<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ sprintf($t('pages.system.admin.group.endpoint.titlePattern'), [adminGroup.name ?? '']) }}
        </h2>

        <router-link to="/system/admin/group" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.system.admin.group.title") }}
        </router-link>
    </div>

    <div class="card mb-5 mb-xl-10" v-for="(group, groupKey, groupIndex) in adminGroupEndpoints" :key="groupIndex">
        <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" :data-bs-target="'#kt_endpoint_' + group.name" aria-expanded="true" aria-controls="kt_endpoints">
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">{{ group.name }}</h3>
            </div>
        </div>

        <div :id="'kt_endpoint_' + group.name" class="collapse show">
            <div class="card-body border-top px-9 pt-3 pb-4">
                <!--begin::Table-->
                <div class="table-responsive">
                    <table class="table table-row-dashed border-gray-300 align-middle gy-6 mb-0" v-for="(groupItem, groupItemKey, groupItemIndex) in group.items" v-bind:class="{'tableClosedExpand': closedExpand(groupIndex + '_' + groupItemIndex)}"
                           :key="groupItemIndex">
                        <thead>
                        <tr class="fs-4 fw-bolder">
                            <th class="w-25px pe-0 ps-0">
                                 <span class="cursor-pointer collapsible rotate" v-bind:class="{collapsed: closedExpand(groupIndex + '_' + groupItemIndex)}" v-on:click="handleExpand(groupIndex + '_' + groupItemIndex)">
                                     <div class="me-3 rotate-90">
                                         <span class="svg-icon svg-icon-3">
                                             <inline-svg src="/media/icons/duotune/arrows/arr071.svg"/>
                                         </span>
                                     </div>
                                 </span>
                            </th>
                            <th class="min-w-400px text-nowrap">{{ groupItem.name }}</th>
                            <th class="w-125px" colspan="4"></th>
                            <!--<th class="w-125px text-nowrap methodColumn" v-for="(method, methodIndex) in methods" :key="methodIndex">
                                   <div class="form-check form-check-solid mb-0">
                                       <input class="form-check-input mt-0" type="checkbox" value="" :id="['GROUP' + (groupIndex + 1), (groupItemIndex + 1), method, (methodIndex + 1)].join('-')" checked data-kt-check="true" data-kt-check-target="[data-kt-settings-notification=email]"/>
                                       <label class="form-check-label ps-2" :for="['GROUP' + (groupIndex + 1), (groupItemIndex + 1), method, (methodIndex + 1)].join('-')">
                                           {{ method }}
                                       </label>
                                   </div>
                                {{ method }}
                            </th>-->
                        </tr>
                        </thead>
                        <tbody class="fs-6 fw-bold">
                        <tr v-for="(endpoint, endpointIndex) in groupItem.items" :key="endpointIndex">
                            <td></td>
                            <td class="text-nowrap">{{ endpoint.path }}</td>
                            <td colspan="4">
                                <div class="d-flex justify-content-end">
                                    <div class="form-check form-check-solid ms-7" v-for="(method, methodIndex) in endpoint.supported_method" :key="methodIndex">
                                        <input class="form-check-input float-none" type="checkbox" @change="changedCheckbox($event, endpoint.id, method)" :value="method" :id="['method', (endpoint.id), (methodIndex + 1)].join('-')" :checked="checkedMethod(endpoint, method)"/>
                                        <label class="form-check-label ps-3" :for="['method', (endpoint.id), (methodIndex + 1)].join('-')">{{ $t('endpointActions.' + method.toLowerCase()) }}</label>
                                    </div>
                                </div>
                            </td>
                            <!--<template v-for="(method, methodIndex) in methodColumns" :key="methodIndex">
                                <td v-if="endpoint.supported_method[method]" class="text-end">
                                    <div class="form-check form-check-solid">
                                        <input class="form-check-input float-none" type="checkbox" @change="changedCheckbox($event, endpoint.id, endpoint.supported_method[method])" :value="method" :id="['method', (endpoint.id), (methodIndex + 1)].join('-')" :checked="checkedMethod(endpoint, method)"/>
                                        <label class="form-check-label ps-3" :for="['method', (endpoint.id), (methodIndex + 1)].join('-')">{{ $t('endpointActions.' + endpoint.supported_method[method].toLowerCase()) }}</label>
                                    </div>
                                <td v-else></td>
                            </template>-->
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!--end::Table-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "_id",
    data() {
        return {
            adminGroup: {
                endpoints: {}
            },
            expand: {
                closed: []
            },
            /*methodColumns: [3, 2, 1, 0]*/
        }
    },
    computed: {
        adminGroupEndpoints() {
            let returnData = {};

            this.$store.state.system.admin.group.endpoint.table.data.forEach((endpoint) => {
                endpoint.supported_method.reverse();

                if (!returnData[endpoint.group]) {
                    returnData[endpoint.group] = {
                        name: endpoint.group,
                        items: {}
                    };
                }

                if (!returnData[endpoint.group].items[endpoint.name]) {
                    returnData[endpoint.group].items[endpoint.name] = {
                        name: endpoint.name,
                        items: []
                    }
                }

                returnData[endpoint.group].items[endpoint.name].items.push(endpoint);
            });

            return returnData;
        },
        adminGroupID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (!this.adminGroupID || !(this.adminGroupID > 0)) {
            this.$router.push({
                path: "/system/admin/group"
            });
        }
    },
    mounted() {
        if (this.adminGroupID && this.adminGroupID > 0) {
            this.$store.dispatch('system/admin/group/endpoint/get');
            this.loadAdminGroup();
        }
    },
    methods: {
        loadAdminGroup() {
            this.axios.get(this.endpoints['admin_group'] + '/' + this.adminGroupID).then((response) => {
                let data = response.data.data;
                let endpoints = {};

                data.endpoints.forEach((endpoint) => {
                    endpoints[endpoint.id] = endpoint;
                });
                data.endpoints = endpoints;

                this.adminGroup = data;
            });
        },
        closedExpand(expandIndex) {
            return this.expand.closed.includes(expandIndex);
        },
        handleExpand(expandIndex) {
            let x = this.expand.closed.indexOf(expandIndex);
            if (x !== -1) {
                this.expand.closed.splice(x, 1);
            } else {
                this.expand.closed.push(expandIndex);
            }
        },
        changedCheckbox($event, endpointID, method) {
            let checked = $event.target.checked;
            let methods = this.adminGroup.endpoints[endpointID] ? this.adminGroup.endpoints[endpointID].method : [];

            if (checked) {
                methods.push(method);
            } else {
                let foundMethodIndex = methods.indexOf(method);
                methods.splice(foundMethodIndex, 1);
            }

            let formData = {
                endpoint_id: endpointID,
                method: methods
            };

            this.axios.put(this.endpoints['admin_group_endpoint'] + '/' + this.adminGroupID, formData).then((response) => {
                this.onResponse(response.data, () => {
                    this.loadAdminGroup();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        },
        checkedMethod(endpoint, method) {
            return this.adminGroup.endpoints[endpoint.id] && this.adminGroup.endpoints[endpoint.id].method.includes(method) // method => endpoint.supported_method[method]
        }
    }
}
</script>

<style>
.tableClosedExpand tbody, .tableClosedExpand thead .methodColumn {
    display: none;
}
</style>